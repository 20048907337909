<template>
<div>
    <div v-if="$auth.isAuthenticated">
        <div class="row">
            <div class="col-md-6"><router-link to="/services"><h2 class="text-center"> List of Services</h2> </router-link>
              </div>
            <div class="col-md-6"><router-link to="/add"><h2 class="text-center">Add Service</h2></router-link></div>
        </div>
    </div>
  <button @click="login" v-if="!$auth.isAuthenticated">
      Login
    </button>
    </div>
</template>

<script>

export default {
  name: "home",
  data() {
    return {
      service: {
        key: "",
        label: "",
        search: "",
        published: false
      },
      submitted: false
    };
  },
  methods: {
 login() { 
    this.$auth.loginWithRedirect(); 
  }, 
  loginPopup() { 
    this.$auth.loginWithPopup(); 
  }, 
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
